<template>
    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg pt-4">
        <div class="container-fluid">
            <div class="row m-0">

                <account-sidebar activeMenu="dashboard" activeBlock="insight">
                </account-sidebar>

                <!-- Item Wrap Start -->
                <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                    <div class="row" >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Breadcrumbs -->
                            <div class="bredcrumb_wrap">
                                <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                                  </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div class="dashboard-stat ">
                                <div >
                                    <i class="bi bi-check-square icon-size" ></i>
                                </div>
                                <div class="dashboard-stat-content">
                                    <h4><span class="cto">{{ totalApp.length }}</span></h4>
                                    <p>Applications</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div class="dashboard-stat">
                                <div >
                                    <i class="bi bi-hourglass-split icon-size" ></i>
                                </div>
                                <div class="dashboard-stat-content">
                                    <h4><span class="cto">{{ ongoingApp.length }}</span></h4>
                                    <p>Ongoing recruitments</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div class="dashboard-stat">
                                <div >
                                    <i class="bi bi-person-check-fill icon-size" ></i>
                                </div>
                                <div class="dashboard-stat-content">
                                    <h4><span class="cto">{{ successfulAppPer }}</span>%</h4>
                                    <p>Success rate</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div class="dashboard-stat">
                                <div >
                                    <i class="bi bi-bookmark-check-fill icon-size" ></i>
                                </div>
                                <div class="dashboard-stat-content">
                                    <h4><span class="cto">{{ recommendationApp.length }}</span></h4>
                                    <p>Employer requests</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <alert-notifications>
                    </alert-notifications>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <relevant-jobs-category>
                            </relevant-jobs-category>
                        </div>


                        <!-- start upcoming activities -->
                        <div class="col-lg-6 col-md-6">
                            <div class="dashboard-gravity-list with-icons">
                                <h4> <i class="bi bi-calendar"></i> Upcoming Activities</h4>
                                <ul>
                                    <li>
                                        <i class="dash-icon-box ti-layers text-purple bg-light-purple"></i> Your job for <strong><a href="#">IOS Developer</a></strong> has been approved!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-success bg-light-success"></i> Jodie Farrell left a review <div class="numerical-rating high" data-rating="5.0"></div> for<strong><a href="#"> Real Estate Logo</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-heart text-warning bg-light-warning"></i> Someone bookmarked your <strong><a href="#">SEO Expert Job</a></strong> listing!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-info bg-light-info"></i> Gracie Mahmood left a review <div class="numerical-rating mid" data-rating="3.8"></div> on <strong><a href="#">Product Design</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-heart text-danger bg-light-danger"></i> Your Magento Developer job expire<strong><a href="#">Renew</a></strong> now it!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-success bg-light-success"></i> Ethan Barrett left a review <div class="numerical-rating high" data-rating="4.7"></div> on <strong><a href="#">New Logo</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-purple bg-light-purple"></i> Your Magento Developer job expire <strong><a href="#">Renew</a></strong> now it.
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- end upcoming activities -->
                    </div>



                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->
</template>

<script>
// @ is an alias to /src
import { ref, inject } from 'vue';
import { useStore } from 'vuex';
import AccountSidebar from '@/components/account/AccountSidebar.vue';
import AlertNotifications from '@/components/alert/AlertNotifications.vue';
import RelevantJobsCategory from '@/components/job/RelevantJobsCategory.vue';

export default {
  name: "AccountDashboard",
  components:{
    AccountSidebar,
    AlertNotifications,
    RelevantJobsCategory
  },
  setup(){

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');


      const existingData = ref(false);
      const reqError = ref("Loading job ad list .... ");

      const totalApp = ref([])
      const ongoingApp = ref([])
      const successfulApp = ref([])
      const successfulAppPer = ref([])
      const recommendationApp = ref([])
      const bookmarkApp = ref([])



          // use axios to get the data
          axios.get(
              coreUrl.backendUrl+'get_client_dashboard_stats',
              {withCredentials: true}).then((response) =>{

              const result = response.data.data;

              console.log(result.application_data)

              totalApp.value = result.application_data
              ongoingApp.value = result.application_data.filter((item) => item.final_step_status !== 'rejected' )
              successfulApp.value = result.application_data.filter((item) => item.step === 'successful' && item.inform_status === 'informed' )
              bookmarkApp.value = result.application_ids.bookmark_ids?JSON.parse(result.application_ids.bookmark_ids):[]

              successfulAppPer.value = totalApp.value.length>0?Math.round(successfulApp.value.length / totalApp.value.length * 100):0

              existingData.value = true;

              // var totalLength = result.length
              //
              // keyRecruitmentStats.total = totalLength
              //
              // for(var i = 0; i < result.application_data; i++){
              //    console.log(result.application_data)
              // }



          }).catch((error)=>{
                  let msg = "Could not fetch your job ads at this time."
                  reqError.value = error.response.data.message.req_msg?msg:msg;
                  existingData.value = false;
          })


          return {
              totalApp,
              ongoingApp,
              successfulAppPer,
              recommendationApp,
              bookmarkApp
          }


  }


};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.icon-size{
    font-size:2rem;
}

</style>
