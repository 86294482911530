<template>
    <!-- ============================ Main Section Start ================================== -->
    <div class="dashboard-gravity-list with-icons">
        <h4><i class="bi bi-megaphone"></i>
            Explore Relevant Opportunities
            <a href="#" class="float-right" data-toggle="modal" data-target="#createRelevantJob">
                <i class=" bi bi-plus"></i>
                new
            </a>
        </h4>
        <ul>
            <li v-for="relevant in relevantJobs" :key="relevant.id" :class="relevant.status?'':'inactive-alert'">
                <i class="dash-icon-box bi " :class="relevant.status?relevant.icon+' text-purple bg-light-purple':relevant.icon+' inactive-alert bg-inactive-alert'"></i>
                    {{ relevant.title }}
                <a href="#" class="float-right" :class="relevant.status?'':'inactive-alert'"><i class=" bi bi-pencil-square"></i></a>
                <a href="#" class="float-right mr-1"
                            :class="relevant.status?'':'inactive-alert'"
                            v-if="!relevant.default"
                 >
                    <i class=" bi bi-trash-fill"></i>
                </a>
            </li>
        </ul>
    </div>
    <!-- ============================ Main Section End ================================== -->

    <base-modal mId="createRelevantJob" mSize="lg" >
         <template #header-left-slot>
             <i class="bi bi-bell-fill"></i>
             Create relevant job config
          </template>
         <template #default>
             <create-reports-relevant-job>
             </create-reports-relevant-job>
         </template>
    </base-modal>
</template>

<script>
// @ is an alias to /src
import { ref } from 'vue';
import { useStore } from 'vuex';
import CreateReportsRelevantJob from '@/components/forms/reports/CreateReportsRelevantJob.vue';

export default {
  name: "RelevantJobsCategory",
  components: {
      CreateReportsRelevantJob
  },
  setup(){

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);

      // get the axios object
      // const axios = inject('axios');  // inject axios

      //get the urls
      // const coreUrl = inject('coreUrl');


      // const existingData = ref(false);
      // const reqError = ref("Loading job ad list .... ");

      // const totalApp = ref([])
      // const ongoingApp = ref([])



          // use axios to get the data
          // axios.get(
          //     coreUrl.backendUrl+'get_client_dashboard_stats',
          //     {withCredentials: true}).then((response) =>{
          //
          //     const result = response.data.data;
          //
          //     console.log(result.application_data)
          //
          //     totalApp.value = result.application_data
          //     ongoingApp.value = result.application_data.filter((item) => item.final_step_status !== 'rejected' )
          //     successfulApp.value = result.application_data.filter((item) => item.step === 'successful' && item.inform_status === 'informed' )
          //     bookmarkApp.value = result.application_ids.bookmark_ids?JSON.parse(result.application_ids.bookmark_ids):[]
          //
          //     successfulAppPer.value = totalApp.value.length>0?Math.round(successfulApp.value.length / totalApp.value.length * 100):0
          //
          //     existingData.value = true;
          //
          //
          //
          // }).catch((error)=>{
          //         let msg = "Could not fetch your job ads at this time."
          //         reqError.value = error.response.data.message.req_msg?msg:msg;
          //         existingData.value = false;
          // })

          const profileData = ref(store.getters["account/getAccountData"]);

          const isLocation = ref(profileData.value.country?true:false)
          const location = ref(profileData.value.country?[profileData.value.city,profileData.value.country]:[])
          const isWorkExperience = ref(profileData.value.work_experience?true:false)
          const work_experience = ref(profileData.value.work_experience?JSON.parse(profileData.value.work_experience):[])
          const isQualifications = ref(profileData.value.qualifications?true:false)
          const qualifications = ref(profileData.value.qualifications?JSON.parse(profileData.value.qualifications):[])
          const isAccomplishments = ref(profileData.value.accomplishments?true:false)
          const accomplishments = ref(profileData.value.accomplishments?JSON.parse(profileData.value.accomplishments):[])
          const isSkills = ref(profileData.value.skills?true:false)
          const skills = ref(profileData.value.skills?JSON.parse(profileData.value.skills):[])



          const relevantJobs = ref([
              {id: "location", title:"Around your location", icon:"bi-pin-map-fill", default:true, status:isLocation.value, params:['location',location.value]},
              {id: "experience",  title:"Relevant to your experience", icon:"bi-briefcase-fill", default:true, status:isWorkExperience.value, params:['experience',work_experience.value]},
              {id: "qualifications", title:"Relevant to your qualifications", icon:"bi-patch-check-fill", default:true, status:isQualifications.value, params:['qualifications',qualifications.value]},
              {id: "accomplishments", title:"Relevant to your accomplishments", icon:"bi-trophy-fill", default:true, status:isAccomplishments.value, params:['accomplishments',accomplishments.value]},
              {id: "skills", title:"Relevant to your skills", icon:"bi-award-fill", default:true, status:isSkills.value, params:['skills',skills.value]}
          ])


          return {
              relevantJobs
          }


  }


};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.icon-size{
    font-size:2rem;
}

.inactive-alert{
    color: #999
}

.bg-inactive-alert{
    background-color: #f5f5f5
}

</style>
