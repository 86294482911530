<template>
    <!-- ============================ Page Title Start================================== -->
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="dashboard-gravity-list with-icons">
                <h4><i class="bi bi-bell-fill"></i> Important Alerts </h4>
                <ul>
                    <li v-for="notify in totalNotification" :key="notify.id"
                        :class="notify.read_status=='read'?'text-secondary':'unread-notify' "
                    >
                        <i class="dash-icon-box bi text-purple bg-light-purple"
                           :class="notifyIcons[notify.notification_category]">
                        </i>
                        <span >
                            {{ notify.info_subject }}
                        </span>
                        <span class="float-right cursor-pointer "
                              :class="notify.read_status=='read'?'text-secondary':'details-btn' "
                              data-toggle="modal" data-target="#notificationDetails"
                              @click="showNotifyDetails(notify)">
                            <i class="bi bi-plus-square-fill"></i> details
                        </span>
                    </li>


                </ul>
            </div>
        </div>

    </div>
    <!-- ============================ Main Section End ================================== -->

    <base-modal mId="notificationDetails" mSize="lg"  :key="currentDetails?currentDetails.id:'old_key'">
         <template #header-left-slot> <i class="bi bi-bell-fill"></i> {{ currentDetails?currentDetails.info_subject:"" }} </template>
         <template #default>
             <div class="m-3">
                 {{ currentDetails?currentDetails.info:"" }}

                 <p class="pt-5" data-dismiss="modal" v-if="compNotifyProfile">
                     <router-link to="/profile" >
                          <button type="button" class="btn btn-save">Go to profile</button>
                     </router-link>
                 </p>

                 <div class="pt-4" v-if="compNotifyRecruitment">
                     <div class="mb-3" v-if="fv.reqFeedback.status">
                         <base-list-tag  :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                     </div>
                      <button type="button" class="btn btn-primary mr-2" @click="updateNotification('accepted')">Accept</button>
                      <button type="button" class="btn btn-outline-secondary" @click="updateNotification('rejected')">Reject</button>
                 </div>

            </div>
         </template>
    </base-modal>
</template>

<script>
// @ is an alias to /src
import { ref, reactive, inject, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "AlertNotifications",
  setup(){
      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);


      const existingData = ref(false);
      const reqError = ref("Loading job ad list .... ");

      const totalNotification = ref([])
      const activeNotification = ref([])
      const activeNotificationSize = ref(0)


      const isCV = ref(false)
      const isProfile = ref(false)
      const isWorkExperience = ref(false)
      const isQualifications = ref(false)
      const isAccomplishment = ref(false)
      const isSKill = ref(false)
      const isCountry = ref(false)


      const notifyIcons = {
          profile:"bi-person-lines-fill",
          recruitment:"bi-megaphone",
          location:"bi-globe2"
      }


          const profileData = ref(store.getters["account/getAccountData"]);

          // use axios to get the data
          axios.get(

              coreUrl.backendUrl+'get_ind_client_notification',
              {withCredentials: true}).then((response) =>{

              const result = response.data.data;

              totalNotification.value = result;
              activeNotification.value = totalNotification.value.filter(( item ) => item.read_status === 'unread')
              activeNotificationSize.value = activeNotification.value.length

              isProfile.value = profileData.value.bio
              isWorkExperience.value = profileData.value.work_experience
              isQualifications.value = profileData.value.qualifications
              isAccomplishment.value = profileData.value.accomplishments
              isSKill.value = profileData.value.skills
              isCountry.value = profileData.value.country

              isCV.value = isProfile.value && isWorkExperience.value && isQualifications.value && isAccomplishment.value &&  isSKill.value ? true:false;

              existingData.value = true;

              if(!isCV.value && existingData.value){
                  const newNotify = {
                      id:"profile_notify",
                      info_subject:"Your resume is still incomplete",
                      info:`You are yet to add your
                          ${isProfile.value?'':'bio , '}
                          ${isWorkExperience.value?'':'work experience , '}
                          ${isQualifications.value?'':'qualifications , '}
                          ${isAccomplishment.value?'':'accomplishments , '}
                          ${isSKill.value?'':'skills'}
                          to your resume. This will impact your chances of being recruited.
                          We advice you to update your profile as soon as possible`,
                      notification_category:"profile",
                      info_link:"profile_location_notify"
                  }

                  totalNotification.value.push(newNotify);
              }


              if(!isCountry.value && existingData.value){
                  const newNotify = {
                      id:"profile_location_notify",
                      info_subject:`You are yet to update your location`,
                      info:`Without your location we will be unable to send job alerts relating to your location.
                      To ensure that you don't miss out on opportunities around your preferred location. Kindly update your profile detail Immediately. `,
                      notification_category:"location",
                      info_link:"profile_location_notify"
                  }

                  totalNotification.value.unshift(newNotify);
              }


          }).catch((error)=>{
                  let msg = "Could not fetch your job ads at this time."
                  reqError.value = error.response.data.message.req_msg?msg:msg;
                  existingData.value = false;
          })

          // create variables for the values to be submitted
         const fv = reactive({
             jobId : {status:false, val:"", msg:false, validate:["required"]},
             notifyId : {status:false, val:"", msg:false, validate:["required"]},
             orgUserid : {status:false, val:"", msg:false, validate:["required"]},
             reqFeedback : {status:false, val:"", msg:false},
             showSubmit : {status:false, val:true}
         })

          const currentDetails = ref(null)

          function showNotifyDetails(details){
              fv.jobId.val = details.info_link
              fv.notifyId.val = details.id
              fv.orgUserid.val = details.sent_by_id
              currentDetails.value = details
          }

          const compNotifyProfile = computed(function(){
              if(currentDetails.value){
                  return currentDetails.value.notification_category === 'profile' || currentDetails.value.notification_category === 'location' ? true:false
              }

              return false;
          })


          const compNotifyRecruitment = computed(function(){
              if(currentDetails.value){
                  return currentDetails.value.notification_category === 'recruitment' && currentDetails.value.notification_type !== 'rejected' && currentDetails.value.read_status === 'unread' ? true:false
              }

              return false;
          })



          async function updateNotification(response_type){


              // load the form data , and append all the necessary fields
              let formData = new FormData();

              console.log(currentDetails.value)
              console.log("urmm")

              formData.append("fv_jobId", fv.jobId.val);
              formData.append("fv_notifyId", fv.notifyId.val);
              formData.append("fv_orgUserid", fv.orgUserid.val);
              formData.append("fv_responseType", response_type);

              // post the data using axios
              try{

                  const response = await axios.put(
                      coreUrl.backendUrl+'update_applicant_recruitment_confirmation',
                      formData,
                      {
                          withCredentials: true,
                          headers:{
                             "Content-Type": "multipart/form-data"
                          }
                      }
                  );

                  // If successful, get the resppnse data
                  const responseData = response.data;

                  if(responseData.status){
                      // update is account created status

                      fv.reqFeedback.status = true;
                      fv.reqFeedback.msg = responseData.message['req_msg'];
                      fv.reqFeedback.val = "success";


                      console.log(fv.notifyId.val)
                      totalNotification.value.find(( item ) => item.id === fv.notifyId.val).read_status = "read"
                       // let currentTotalNotification = totalNotification.value.filter(( item ) => item.id === fv.notifyId.val)

                  }else{

                      for(const key in responseData.message){
                          if(fv[key]){
                              fv[key]["status"] = false;
                              fv[key]["msg"] = responseData.message[key];
                          }
                      }


                      fv.reqFeedback.status = true;
                      fv.reqFeedback.msg = responseData.message['req_msg'];
                      fv.reqFeedback.val = "danger";

                  }




              }catch(error){

                  console.log(error)
                  // load the error to the appropriate fiel

                  fv.reqFeedback.status = true;
                  fv.reqFeedback.msg = "We had issues processing your request";
                  fv.reqFeedback.val = "danger";

                  // end load the error to the appropriate field

              }

           }


          return {
              fv,
              totalNotification,
              activeNotification,
              notifyIcons,
              showNotifyDetails,
              currentDetails,
              compNotifyProfile,
              compNotifyRecruitment,
              updateNotification
          }


  }


};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.icon-size{
    font-size:2rem;
}

.details-btn{
    font-size:0.9rem;
    color:#1a274e;
}

.unread-notify{
    font-weight: bold;
}

</style>
